<template>
  <div>
    <custom-toolbar
      :title="$t('Global_values')"
      :total="$store.getters['setting/total']"
      @refresh="getItems"
    />
    <v-card class="mb-6 mt-6">
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.setting.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details
              clearable
              @input="getItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['setting/settings']"
        :items-per-page="$store.getters['setting/per_page']"
        :page.sync="$store.getters['setting/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ item.updated_at ? formattingDate(`${item.updated_at}`) : null }}
        </template>

        <template #[`item.value`]="{item}">
          <v-chip
            v-if="item.setting_types.code === 'COLOR'"
            small
            :color="item.value"
          >
            {{ item.value }}
          </v-chip>
          <p v-else>
            {{ item.value }}
          </p>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :lock="false"
            :status="false"
            :view="false"
            :remove="false"
            @edit="openForm(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              v-model="$store.state.setting.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getItemsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getItems"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <setting-form-dialog
      :dialog="dialog"
      :setting="setting"
      @clickout="closeDialog"
      @submitted="loading"
    />
  </div>
</template>

<script>
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { mapGetters, mapState } from 'vuex'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLock, mdiLockOpen,
  mdiPencilOutline, mdiPlus, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import SettingFormDialog from '@core/components/setting/SettingFormDialog'
import { formatDate } from '@core/date/dateFunctions'
import { ref, watch, onMounted } from '@vue/composition-api'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Setting',

  components: {
    ButtonsGroup, CustomToolbar, SettingFormDialog, LoaderDialog,
  },

  setup() {
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const settingForm = ref({
      id: null,
      label: null,
      key: null,
      value: null,
    })
    const setting = ref(settingForm.value)
    const confirmMessage = 'Voulez-vous désactiver cette marge?'

    const getItems = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('setting/getSettingList', { page, field: store.state.setting.field }).then(() => {
        currentPage.value = store.getters['setting/current_page']
        lastPage.value = store.getters['setting/last_page']
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    }

    const refreshItems = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('setting/refreshSettingList', { page, field: store.state.setting.field }).then(() => {
        currentPage.value = store.getters['setting/current_page']
        lastPage.value = store.getters['setting/last_page']
        isDialogVisible.value = false
      })
    }

    watch(() => store.state.user.lang, lang => {
      refreshItems(store.getters['setting/current_page'])
    })

    // eslint-disable-next-line camelcase
    const getItemsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.setting.field.paginate = per_page
        store.dispatch('setting/getSettingList', {
          page: currentPage.value,
          field: store.state.setting.field,
        }).then(() => {
          currentPage.value = store.getters["setting/current_page"]
          lastPage.value = store.getters["setting/last_page"]
          isDialogVisible.value = false
        })
      }
    }

    const openForm = (item = null) => {
      if (item !== null) setting.value = item
      else {
        this.initForm()
        setting.value = settingForm.value
      }
      dialog.value = true
    }

    const initForm = () => {
      Object.keys(settingForm).forEach(key => {
        this.rules[key] = null
      })
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const formattingDate = date => formatDate(date)

    const deleteItemConfirm = () => {
      closeDelete()
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const closeDialog = () => {
      dialog.value = false
    }

    onMounted(() => {
      getItems(store.getters['setting/current_page'])
    })

    return {
      formattingDate,
      deleteItemConfirm,
      closeDelete,
      closeDialog,
      openForm,
      initForm,
      loading,
      getItemsPerPage,
      getItems,
      currentPage,
      lastPage,
      dialogDelete,
      isDialogVisible,
      dialog,
      setting,
      settingForm,
      confirmMessage,
      headers: [
        { text: 'LIBELLE', value: 'label' },
        { text: 'CLE', value: 'key' },
        { text: 'VALEUR', value: 'value' },
        { text: 'TYPE', value: 'setting_types.label' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
        mdiRefresh,
        mdiLock,
      },
    }
  },
}
</script>

<style scoped>

</style>
